<template>
  <div class="news">
    <div class="action-bar">
      <router-link to="/news/create">
        <el-button type="primary" icon="el-icon-edit">发布新闻</el-button>
      </router-link>
      <el-input v-model="keyword" placeholder="输入关键字搜索新闻" class="search" @keyup.enter.native="search">
        <el-button icon="el-icon-search" @click="search" slot="append"></el-button>
      </el-input>
    </div>
    <el-card shadow="hover">
      <el-table :data="list" size="mini" v-loading="loading">
        <el-table-column label="ID" prop="id" width="80"></el-table-column>
        <el-table-column label="封面图" width="100px">
          <template slot-scope="scope">
            <a :href="scope.row.image_url" target="_blank">
              <img :src="scope.row.image_url" alt="封面图" style="width: 50px;">
            </a>
          </template>
        </el-table-column>
        <el-table-column label="新闻标题" prop="title">
          <template slot-scope="scope">
            {{ scope.row.title }}
            <a :href="scope.row.link" target="_blank" v-if="scope.row.link"><i class="el-icon-s-promotion"></i></a>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="created_at" width="150"></el-table-column>
        <el-table-column label="最后更新" prop="updated_at" width="150"></el-table-column>
        <el-table-column label="阅读量" prop="views" width="100"></el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" type="success" v-if="scope.row.state">已发布</el-tag>
            <el-tag size="mini" type="info" v-else>未发布</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="首页显示" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" type="success" v-if="scope.row.home_display === 'y'">显示</el-tag>
            <el-tag size="mini" type="info" v-else>不显示</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <router-link :to="{ name: 'newsEdit', query: { id: scope.row.id } }">
              <el-button type="primary" size="mini">编辑</el-button>
            </router-link>
            <el-button type="danger" size="mini" @click="deleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :currentChange="currentChange"
        :total="total"
        :layout="pageLayout"
        :page-size="pageSize"
        background></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { newsDelete, newsList } from '@/request/modules/news'
import { PAGE_LAYOUT, PAGE_SIZE } from '@/config/app'

export default {
  data () {
    return {
      list: [],
      loading: false,
      page: 1,
      total: 0,
      keyword: '',
      pageLayout: PAGE_LAYOUT,
      pageSize: PAGE_SIZE
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      newsList({ keyword: this.keyword, page: this.page }).then(resp => {
        this.list = resp.list
        this.total = resp.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    search () {
      this.currentChange(1)
    },
    currentChange (page) {
      this.page = page
      this.fetchData()
    },
    deleteItem (row) {
      this.$confirm(`确定删除${row.title}?`, {}).then(() => {
        newsDelete({ id: row.id }).then(resp => {
          this.$message.success('操作成功!')
          this.fetchData()
        }).catch(() => {})
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
